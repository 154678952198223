import {
  allState as allPrintersAndLabelsState,
  createQueriesHook as createPrinterAndLabelQueries,
  createSavePrinterHandler,
  validatePrinter,
} from '../../../utilities/printersAndLabels';
import { Button } from 'semantic-ui-react';
import { PrinterAndLabelForm } from 'components/Forms/PrinterAndLabelForms';
import React from 'react';

const formHasNoErrors = ({ errors }) => Object.keys(errors).length === 0;

export const MainPage = ({ nextInternalStep }) => {
  const forms = {};
  const allForms = () => Object.values(forms);

  const { printersAndLabelsState } = allPrintersAndLabelsState();

  const printerAndLabelQueries = createPrinterAndLabelQueries({
    printersAndLabelsState,
  });

  const printerSaveHandler = createSavePrinterHandler({
    printersAndLabelsState,
  });

  const addFormForSubmission = formProps => {
    forms[formProps.values.id] = formProps;
  };

  const submitAllForms = async () => {
    await Promise.all(allForms().map(item => item.submitForm()));

    return allForms().every(formHasNoErrors);
  };

  const confirmPrinters = async () => {
    if (await submitAllForms()) nextInternalStep();
  };

  const printerSections = [
    {
      printer: printerAndLabelQueries.plantTagPrinter,
      title: 'Plant Tag Printer & Labels',
      id: 'plantTag',
    },

    {
      printer: printerAndLabelQueries.inventoryTagPrinter,
      title: 'Inventory Tag Printer & Labels',
      id: 'inventoryTag',
    },

    {
      printer: printerAndLabelQueries.retailLabelPrinter,
      title: 'Retail Label Printer & Labels',
      id: 'retailTag',
    },
  ];

  const printerSection = ({ title, printer, id }) => (
    <div>
      <h2>{title}</h2>
      <PrinterAndLabelForm
        id={id}
        key={id}
        registerForm={addFormForSubmission}
        validator={validatePrinter}
        onSubmit={printerSaveHandler}
        submitText="Save"
        includeActions={false}
        fieldValues={printer}
      />
      <br />
    </div>
  );

  return (
    <>
      <h3>Understanding Printers in Traceability</h3>

      <p>
        Printers are a critical piece of hardware for your operation, especially when it
        comes to traceability.
      </p>

      <p>
        Often times, it is best to get separate printers for different tasks during the
        growing and selling cycle - namely for tagging plants, tagging inventory and
        creating retail ready labels for orders.
      </p>

      <p>
        Let us know which printers you use for each of these areas of work at your
        operation.
      </p>

      {printerSections.map(printerSection)}

      <Button onClick={confirmPrinters}>Confirm Printers</Button>
    </>
  );
};
