import {
  InternalStepProgress,
  PageWithIntro,
  PrintersConfirmation,
  WizardLayout,
} from 'components';
import { MainPage } from './_MainPage';
import React from 'react';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import { printersAndLabelsStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { useTracking } from '../../../utilities/hooks';

export const PrintersAndLabelsPage = props => {
  useTracking('PrintersAndLabels');

  const stepCompleted = stepIsComplete(printersAndLabelsStep);

  return (
    <PageWithIntro step={printersAndLabelsStep}>
      <WizardLayout text>
        <h1>Add Printers Details</h1>
        <WistiaVideo id={printersAndLabelsStep.intro.videoID} />
        <InternalStepProgress
          stepsConfig={[
            {
              step: 1,
              label: '1. Add Printer Details',
              component: <MainPage {...props} />,
            },
            {
              step: 2,
              label: '2. Confirm Printer Details',
              component: (
                <PrintersConfirmation {...props} stepCompleted={stepCompleted} />
              ),
            },
          ]}
        />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default PrintersAndLabelsPage;
